import React from 'react'
import {OfferTilesStoryblok} from 'types/storyblok-types'
import {HeaderBlock, HeaderType} from './Header'
import Image from 'next/image'
import ButtonBlock from 'components/shared/ButtonBlock'
import Link from 'next/link'
import {storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'

type OfferTilesPropsType = {
  title: OfferTilesStoryblok['title']
  offerTiles: OfferTilesStoryblok['offer_tiles']
  blok: OfferTilesStoryblok
}

export const OfferTilesBlock = ({
  title,
  offerTiles,
  blok,
}: OfferTilesPropsType) => {
  return (
    <div className="block">
      <HeaderBlock
        title={title}
        type={HeaderType.h2}
        {...storyblokEditable(blok)}
      />
      <div className="flex flex-col gap-10 md:flex-row">
        {offerTiles.map(tile => (
          <div key={tile._uid} className="flex flex-col md:w-1/3">
            <div className="flex">
              {tile.title_icon?.filename ? (
                <span className="relative mr-2 mt-1 inline-block size-5">
                  <Image
                    src={tile.title_icon.filename}
                    alt={tile.title_icon.alt ? tile.title_icon.alt : ``}
                    fill
                    className="object-contain"
                  />
                </span>
              ) : null}
              <HeaderBlock title={tile.title} type={HeaderType.h3} />
            </div>
            <div className="mb-5">{tile.text}</div>
            <Link
              href={
                linkTypeChecker(tile?.button[0]?.link)
                  ? linkTypeChecker(tile?.button[0]?.link)
                  : '#'
              }
              target={tile.button[0].link.target}
              className="mt-auto no-underline"
            >
              <ButtonBlock
                title={tile.button[0]?.button_label}
                type="button"
                styling={tile.button[0]?.button_color}
                className="!md:w-[201px] !w-full"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
const OfferTilesContainer = ({blok}: {blok: OfferTilesStoryblok}) => {
  return (
    <OfferTilesBlock
      title={blok.title}
      offerTiles={blok.offer_tiles}
      blok={blok}
    />
  )
}

export default OfferTilesContainer
